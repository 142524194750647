import React from 'react';
import '../App.css'; // Asegúrate de crear este archivo de estilos


import equipopie2024 from '../img/pie2024.jpg';
import  uniforme from '../img/uniformenz.jpg';
// import  reunioncursos from '../img/reunion.jpg';
// import  Aviso3 from '../img/invitacion.jpg';



const EventsComponent = () => {
  const eventsData = [
    /* {
      id: 3,
      date: 'Encuentro Intercultural 2024',
      title: '',
      description: '',
      imageUrl: Aviso3, // Agrega la ruta de la imagen correspondiente
    }, */

    /*{
      id: 2,
      date: 'Reunión de Apoderados',
      title: 'Miercoles 09 de Octubre 2024',
      description: '',
      imageUrl: reunioncursos, // Agrega la ruta de la imagen correspondiente
    }, */ 

      
    {
      id: 1,
      date: 'Equipo P.I.E 2024',
      title: '',
      description: '',
      imageUrl: equipopie2024, // Agrega la ruta de la imagen correspondiente
    },

    {
      id: 0,
      date: 'Uniforme Escolar',
      title: 'Escuela Nueva Zelandia 2024',
      description: '',
      imageUrl: uniforme, // Agrega la ruta de la imagen correspondiente
    },
    
    
    

    // Agrega más eventos aquí
  ];

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  return (
    <div id="events-component" className="events-component">
      <h2> Informaciones (Doble click Para agrandar imagenes)</h2>
      <div className="event-cards-container">
        {eventsData.map((event) => (
          <div key={event.id} className="event-card">
            <div className="event-date">{event.date}</div>
            <div className="event-details">
              <h3>{event.title}</h3>
              <div className="event-description"></div>
              <p>{event.description}</p>
              <img
                src={event.imageUrl}
                alt={event.title}
                onDoubleClick={() => openImageInNewTab(event.imageUrl)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsComponent;
